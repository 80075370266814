import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.header<{ $hidden?: boolean }>`
	display: ${(props) => (props.$hidden ? "none" : "block")};

	position: sticky;
	z-index: 999;
	top: 0;
	background: var(--white);
	border-bottom: 1px solid var(--purple30);
	.header-container {
		display: flex;
		box-sizing: border-box;
		width: 100%;
		height: 52px;
		align-items: center;
		gap: 40px;
		flex-shrink: 0;
		.terms {
			span {
				display: block;
			}
			svg {
				display: none;
			}
		}
		.logo {
			display: flex;
			color: var(--purple100);
			width: 76px;
			button > div {
				display: flex;
			}
		}

		.content {
			width: 100%;
			.navigationMenu {
				display: flex;
				gap: 30px;
				list-style: none;
				li {
					cursor: pointer;
					a {
						font-family: "Helvetica Bold";
						font-size: 16px;
						font-style: normal;
						line-height: 19.2px;
						text-decoration: none;
						padding-bottom: 18px;
						color: var(--gray90);
						&:hover {
							color: var(--gray110);
							border-bottom: 1px solid var(--gray110);
						}
						&.active {
							color: var(--purple100);
							border-bottom: 1px solid var(--purple100);
						}
					}
				}
			}
		}
		.header-action-icons {
			margin-left: 16px;
			display: flex;
			gap: 16px;
			justify-content: end;
			align-items: center;
			width: 100%;
			max-width: 76px;
			.action {
				position: relative;
				height: 50px;
				width: 40px;
				justify-content: center;
				display: flex;
				align-items: center;
			}
			.cart-items {
				position: absolute;
				top: 10px;
				left: 15px;
				border-radius: 100%;
				width: 16px;
				height: 16px;
				background-color: var(--purple100);
				color: var(--white);
				font-size: 12px;
				line-height: 12px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-indent: 0;
			}
		}

		.product-details {
			display: flex;
			justify-content: center;
			.product-details-divider {
				margin: 0 16px;
				background: var(--purple30);
				width: 1px;
				height: 20px;
			}

			.product {
				&.active {
					font-family: "Helvetica Bold";
				}
			}
		}
	}

	.mobile-menu {
		display: none;
	}

	@media only screen and (max-width: ${breakpoints.desktop}px) {
		.header-container {
			justify-content: space-around;
			gap: 20px;
			.content {
				.navigationMenu {
					display: none;
				}
				.product-details {
					.product-details-divider {
						display: none;
					}
					.product {
						display: none;
						&.active {
							display: block;
						}
					}
				}
			}
			.action {
				.logged-actions {
					width: 100%;
					&.open {
						display: flex;
						width: 100vw;
						left: 0;
						position: fixed;
						flex-direction: column;
						align-items: end;
						background: var(--white);
						justify-content: space-around;
						padding: 20px;
						height: 100vh;
						align-items: baseline;
						justify-content: start;
						gap: 24px;
						.action-item,
						button {
							width: 100%;
						}
						.action-item-text {
							padding: 8px 4px;
							font-family: "Helvetica Bold";
						}
					}
					&.closed {
						display: none;
					}
				}
			}
		}

		.userMenu {
			display: none;
		}
		.mobile-menu {
			display: block;
			button {
				svg:first-child {
					display: none;
				}
				svg:last-child {
					display: block;
				}
			}
			.open {
				button {
					svg:first-child {
						display: block;
					}
					svg:last-child {
						display: none;
					}
				}
			}
		}
	}
	@media only screen and (max-width: ${breakpoints.mobile}px) {
		.header-container {
			.terms {
				span {
					display: none;
				}
				svg {
					display: inline;
				}
			}
		}
	}
`;
