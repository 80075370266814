import styled from "styled-components";

export const Wrap = styled.svg<{ $info?: boolean }>`
	&:not(.clean) {
		path {
			fill: ${(props) => (props.$info ? "var(--purple100)" : "var(--gray90)")};
		}
		&:hover {
			path {
				fill: ${(props) =>
					props.$info ? "var(--purple110)" : "var(--gray110)"};
			}
		}
	}
`;
