import styled from "styled-components";
import { rotate } from "../../design-system/animations";

export const Wrap = styled.div<{
	$height?: string;
	$width?: string;
	$draggable?: boolean;
	$maskId?: string | null;
}>`
	width: ${(props) => (props.$width ? `${props.$width}` : "100%")};
	height: ${(props) => (props.$height ? `${props.$height}` : "100%")};

	${({ $draggable, $maskId }) =>
		!$draggable &&
		$maskId &&
		`
      mask-image: url(${$maskId});
      mask-size:100%;
    `} // item_id + print_location .svg
  .moveable-line.moveable-direction {
		background: var(--purple100);
	}
	.moveable-control.moveable-direction {
		background: var(--purple100);
		width: 10px;
		height: 10px;
		margin-top: -5px;
		margin-left: -5px;
		border: 1px solid #fff;
	}

	.artwork-img {
		max-width: 100%;
		max-height: 100%;
	}

	.loading-artwork {
		width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		svg {
			animation: ${rotate} 2s linear infinite;
			height: 16px;
			width: 16px;
		}
	}
`;
