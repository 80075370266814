import React from "react";
import Icon from "../Icon";
import { Wrap } from "./styles";
import { loadingLogo } from "../../constants/icons";

const LoadingIcon = () => {
	return (
		<Wrap className="loading-icon">
			<Icon icon={loadingLogo} class="clean" />
		</Wrap>
	);
};

export default LoadingIcon;
