import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../store";
import { isLoggedRequest } from "../store/Login";
import { setCookie } from "./cookies";

interface ProtectedRouteProps {
	children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const login = useSelector((state: RootState) => state.login);
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();

	useEffect(() => {
		if (token) {
			setCookie("token", token);
		}
		dispatch(isLoggedRequest());
	}, [dispatch, token]);

	if (login.logged === null) return;
	if (!login.logged && !login.loading) {
		return <Navigate to="/access" state={{ from: location }} replace />;
	}
	return children;
};

export default ProtectedRoute;
