export const front_center = {
	path: '<rect opacity="0.5" width="440" height="616" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="439" height="615" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M220 4L220 612" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M4 308L436 308" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 440 616",
	initialWidth: "440",
	initialHeight: "616",
};

export const full_back = {
	path: '<rect opacity="0.5" width="440" height="616" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="439" height="615" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M220 4L220 612" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M4 308L436 308" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 440 616",
	initialWidth: "440",
	initialHeight: "616",
};

export const full_back_ss = {
	path: '<rect opacity="0.5" width="440" height="616" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="439" height="615" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M220 4L220 612" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M4 308L436 308" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 440 616",
	initialWidth: "440",
	initialHeight: "616",
};

export const full_center = {
	path: '<rect opacity="0.5" width="440" height="616" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="439" height="615" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M220 4L220 612" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M4 308L436 308" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 440 616",
	initialWidth: "440",
	initialHeight: "616",
};

export const full_center_ss = {
	path: '<rect opacity="0.5" width="440" height="616" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="439" height="615" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M220 4L220 612" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M4 308L436 308" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 440 616",
	initialWidth: "440",
	initialHeight: "616",
};

export const front_left = {
	path: '<rect opacity="0.5" width="66" height="66" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="65" height="65" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M33 2L33 64" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M2 33L64 33.0001" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 66 66",
	initialWidth: "17",
	initialHeight: "17",
};

export const label_neck = {
	path: '<rect opacity="0.5" x="1.5" y="1" width="127" height="25.4" fill="#F2F1F4"/><rect x="1" y="0.5" width="128" height="26.4" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="65.877" y1="1" x2="65.877" y2="26.4" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="1.5" y1="13.0103" x2="128.5" y2="13.0103" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 130 28",
	initialWidth: "130",
	initialHeight: "28",
};

export const cap_front = {
	path: 'rect opacity="0.5" width="154" height="88" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="152.936" height="87" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="77.0005" y1="-2.82672e-08" x2="77.0005" y2="87.1814" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="1.27414e-07" y1="43.2952" x2="154" y2="43.2952" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 154 88",
	initialWidth: "154",
	initialHeight: "88",
};

export const knit_front = {
	path: '<rect opacity="0.5" width="330" height="330" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="328.864" height="329" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="164.428" y1="-1.31914e-08" x2="164.428" y2="326.93" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="3.39769e-08" y1="163.732" x2="330" y2="163.732" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 330 330",
	initialWidth: "330",
	initialHeight: "330",
};

export const puffer_front = {
	path: '<rect opacity="0.5" width="660" height="264" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="658.728" height="263" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="328.358" y1="-2.19856e-09" x2="328.358" y2="261.544" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="1.41571e-08" y1="130.886" x2="660" y2="130.887" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 660 264",
	initialWidth: "660",
	initialHeight: "264",
};

export const tank_top_full = {
	path: '<rect opacity="0.5" width="440" height="550" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="438.819" height="549" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="219.073" y1="-3.29784e-09" x2="219.073" y2="544.884" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="6.79539e-09" y1="273.221" x2="440" y2="273.222" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 440 550",
	initialWidth: "440",
	initialHeight: "550",
};

export const tank_top_left_center = {
	path: '<rect opacity="0.5" width="88" height="176" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="86.9637" height="175" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="44.214" y1="-1.64892e-08" x2="44.214" y2="174.363" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="2.12356e-08" y1="87.0908" x2="88" y2="87.0908" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 440 550",
	initialWidth: "440",
	initialHeight: "550",
};

export const hoodie_front = {
	path: '<rect opacity="0.5" width="770" height="550" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="768.682" height="549" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="382.997" y1="-1.88448e-09" x2="382.997" y2="544.884" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="6.79539e-09" y1="273.222" x2="770" y2="273.222" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 770 550",
	initialWidth: "770",
	initialHeight: "550",
};

export const hoodie_front_ss = {
	path: '<rect opacity="0.5" width="770" height="550" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="768.682" height="549" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="382.997" y1="-1.88448e-09" x2="382.997" y2="544.884" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="6.79539e-09" y1="273.222" x2="770" y2="273.222" stroke="#7745FF" stroke-dasharray="2 2"/>',
	viewBox: "0 0 770 550",
	initialWidth: "770",
	initialHeight: "550",
};

export const cages = {
	front_center: front_center,
	front_left: front_left,
	full_back: full_back,
	full_back_ss: full_back,
	full_center: full_center,
	full_center_ss: full_center_ss,
	hoodie_front: hoodie_front,
	hoodie_front_ss: hoodie_front_ss,
	label_neck: label_neck,
	cap_front: cap_front,
	knit_front: knit_front,
	puffer_front: puffer_front,
	tank_top_full: tank_top_full,
	tank_top_left_center: tank_top_left_center,
};

export const MINIMUM_SIZE_CM = 2.5;
export const MAXIMUM_WIDTH_SIZE_CM = 35;
export const MAXIMUM_HEIGHT_SIZE_CM = 28;

export const MAXIMUM_DTF_WIDTH_SIZE_CM = 28;
export const MAXIMUM_DTF_HEIGHT_SIZE_CM = 20;

export const MAXIMUM_SCREEN_PRINT_WIDTH_SIZE_CM = 38;
export const MAXIMUM_SCREEN_PRINT_HEIGHT_SIZE_CM = 25;

export const MAXIMUM_EMBROIDERY_AREA = 250;
export const MAXIMUM_EMBROIDERY_SIZE_CM = 15;

export const LABEL_MINIMUM_WIDTH_SIZE_CM = 4;
export const LABEL_MINIMUM_HEIGHT_SIZE_CM = 0.7;
