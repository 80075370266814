import styled from "styled-components";

export const Wrap = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 24px;
	.list-details {
		width: 50%;
		display: flex;
		flex-direction: column;
		gap: 16px;
		div {
			word-wrap: wrap;
		}
	}

	&.error {
		color: var(--red110);
		background-color: var(--red10);
		.list-details {
			padding: 8px;
		}
		/* padding: 16px; */
		.error {
			border-top: 1px solid var(--red100);
			border-radius: 0;
			font-size: 12px;
		}
	}
`;
