import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { Wrap } from "./styles";
import { getUserDesignCostBreakdownRequest } from "../../store/UserDesign";
import LoadingOptions from "../LoadingOptions";

const SummaryCostBreakdown = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const userDesignState = useSelector((state: RootState) => state.userDesign);
	const userDesignCost = userDesignState.cost;
	const userDesignId = userDesignState.data?.id;

	useEffect(() => {
		if (userDesignId) {
			dispatch(getUserDesignCostBreakdownRequest(String(userDesignId)));
		}
	}, [dispatch, userDesignId]);

	return (
		<Wrap>
			{userDesignCost ? (
				<>
					<div className="cost-row">
						<div className="label-lg">{t("summary.breakdown")}</div>
						<div className="label-lg">{t("summary.price")}</div>
					</div>
					<div className="cost-row">
						<div className="body-sm">{t("summary.fabric")}</div>
						<div className="body-sm">
							{userDesignCost.item_cost.toFixed(2)} €
						</div>
					</div>
					{userDesignCost.prints.map((print, k) => (
						<div className="cost-row" key={`${k}_print_cost`}>
							<div className="body-sm">
								{t(`printLocation.${print.location}`)}{" "}
								{t(`printType.label.${print.technique}`)}
							</div>
							<div className="body-sm">{print.cost.toFixed(2)} €</div>
						</div>
					))}
				</>
			) : (
				<LoadingOptions />
			)}
		</Wrap>
	);
};

export default SummaryCostBreakdown;
