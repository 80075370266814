import { PaymentStatusEnum, UserDesign, UserDesignProduction } from "../beans";

export const hasPendingOrders = (userDesign: UserDesign) => {
	const productions = userDesign.production_order_details.productions;
	if (!productions.length) return false;
	if (userDesign.production_order_details.production_without_order)
		return false;
	const pendingOrders = productions.map((production: UserDesignProduction) => {
		if (!production.payments) {
			return !!production.order;
		} else {
			const payments = production.payments.map(
				(payment: { status: PaymentStatusEnum }) => {
					return payment.status !== PaymentStatusEnum.succeeded;
				}
			);
			return !payments.every((p: boolean) => p === false);
		}
	});
	return !pendingOrders.every((p) => p === false);
};

export const hasClosedOrder = (userDesign: UserDesign) => {
	const productions = userDesign.production_order_details?.productions;
	if (productions === undefined) return false;
	const result = productions?.find(
		(production) => production.order_created !== false
	);

	return result;
};

// TO REMOVE
export const getPendingOrder = (userDesign: UserDesign) => {
	const productions = userDesign.production_order_details?.productions || null;

	if (!productions) return null;
	const pendingOrder = productions.map((production: UserDesignProduction) => {
		if (!production.payments) {
			return {
				productionId: production.production_id,
				orderId: production.order,
			};
		}
		return null;
	});
	return pendingOrder[0] ?? null;
};

export const calculateTotalProductionItems = (data: Record<string, number>) => {
	let sum = 0;
	if (data) {
		Object.keys(data).forEach((key) => {
			sum += Number(data[key as keyof object]);
		});
	}
	return sum;
};
