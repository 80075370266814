import styled from "styled-components";

export const Wrap = styled.div`
	&.artwork-area {
		position: absolute;
		z-index: 2;

		svg {
			position: absolute;
		}

		&.front_center {
			width: 28%;
			height: 9.3%;
			top: 30%;
			left: 50%;
			transform: translate(-50%, -50%);

			&.item_014,
			&.item_015 {
				top: 33%;
			}

			&.item_016 {
				top: 31.5%;
			}

			img {
				position: absolute;
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&.front_left {
			width: 4%;
			height: 3.3%;
			top: 27%;
			left: 61%;
			transform: translate(-50%, -50%);
			img {
				position: absolute;
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&.hoodie_front,
		&.hoodie_front_ss {
			width: 38%;
			height: 23%;
			top: 30%;
			left: 50%;
			transform: translate(-50%, -50%);

			&.item_014 {
				top: 31%;
			}
		}

		&.full_back,
		&.full_back_ss {
			width: 32%;
			height: 36.5%;
			top: 37%;
			left: 50%;
			transform: translate(-50%, -50%);

			&.item_009,
			&.item_010 {
				width: 24.5%;
				height: 28.5%;
				top: 35%;
				left: 50%;
			}

			&.item_014,
			&.item_015 {
				top: 39.7%;
			}
			&.item_016,
			&.item_017 {
				top: 43.4%;
			}

			&.item_018 {
				top: 38.5%;
				left: 48.5%;
			}
			&.item_019 {
				top: 38.999%;
			}

			img {
				position: absolute;
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&.full_center,
		&.full_center_ss {
			width: 31%;
			height: 36.5%;
			top: 37%;
			left: 50%;
			transform: translate(-50%, -50%);

			&.item_003,
			&.item_004,
			&.item_005,
			&.item_006 {
				top: 39%;
			}
			&.item_009,
			&.item_010 {
				width: 24.5%;
				height: 28.5%;
				top: 35%;
				left: 50%;
			}
			&.item_014,
			&.item_015 {
				top: 39.7%;
			}
			&.item_016,
			&.item_017 {
				top: 43.4%;
			}

			&.item_018 {
				top: 38.5%;
				left: 48.5%;
			}
			&.item_019 {
				top: 38.999%;
			}

			img {
				position: absolute;
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&.cap_front {
			width: 19%;
			height: 9.3%;
			top: 45%;
			transform: translate(-48%, -50%);
			left: 50%;

			&.item_049 {
				&.side {
					transform: translate(-95%, -50%) skew(-25deg, 5deg) scale(90%);
					perspective: 100px;
				}
				&.back {
					display: none;
				}
			}

			img {
				position: absolute;
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&.knit_front {
			width: 15%;
			height: 12.5%;
			top: 33%;
			left: 62%;
			transform: translate(-50%, -50%);
			img {
				position: absolute;
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&.puffer_front {
			width: 35%;
			height: 12%;
			top: 32%;
			left: 50%;
			transform: translate(-50%, -50%);
			img {
				position: absolute;
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
			}
		}

		.artwork-preview {
			position: absolute;
			z-index: 2;
			width: 100%;
			height: 55px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
`;
