import React from "react";
import {
	ButtonEnum,
	ButtonTypeEnum,
	Country,
	Shipping,
	billingForm,
} from "../../beans";
import Button from "../Button";
import { Wrap } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { goToOrderCheckout } from "../../constants/routes";

import SubsectionTitle from "../SubsectionTitle";

import FormContainer from "../FormContainer";
import { getCountryList } from "../../api/countries";
import {
	setShippingRequest,
	updateShippingRequest,
} from "../../store/Shipping";
import { setBillingRequest, updateBillingRequest } from "../../store/Billing";
import { useNavigate } from "react-router";
import { billingSchema } from "../../utils/form";
import { useTranslation } from "react-i18next";
import { sanitizeData } from "../../utils/sanitize";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

type Props = {
	orderId: string;
	shippingData: Shipping;
	shippingIsValid?: boolean;
	shippingIsDirty?: boolean;
	shippingFormRef: React.RefObject<HTMLFormElement>;
};

const BillingForm = (props: Props) => {
	const { orderId, shippingData } = props;
	const navigate = useNavigate();
	const { t } = useTranslation();

	const dispatch = useDispatch<AppDispatch>();
	const [countries, setCountries] = useState([]);

	const shipping = useSelector(
		(state: RootState) => state.shipping.data?.shipping_address
	);
	const billing = useSelector((state: RootState) => state.billing.data);

	useEffect(() => {
		const fetchCountries = async () => {
			const response = await getCountryList();

			setCountries(response.data);
		};
		fetchCountries();

		if (billing?.billing_id) {
			setFormData(billing);
		}
	}, [billing]);

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		company_name: "",
		vat_number: "",
		address_line_1: "",
		address_line_2: "",
		postcode: "",
		province: "",
		city: "",
		country: "",
	});

	// HOOK-FORM
	const {
		control,
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<billingForm>({
		resolver: yupResolver(billingSchema),
		defaultValues: billing ? billing : formData,
		shouldFocusError: false,
		mode: "onChange",
	});

	const handleOnSubmit = () => {
		if (orderId !== undefined) {
			const billingBody = {
				orderId: orderId,
				payload: sanitizeData(getValues()),
			};
			const shippingBody = {
				navigate: navigate,
				orderId: orderId,
				shippingId: shipping?.shipping_id,
				payload: sanitizeData(shippingData),
			};

			billing?.billing_id
				? dispatch(updateBillingRequest(billingBody))
				: dispatch(setBillingRequest(billingBody));

			shipping?.shipping_id
				? dispatch(updateShippingRequest(shippingBody))
				: dispatch(setShippingRequest(shippingBody));

			if (orderId) navigate(goToOrderCheckout(orderId));
		} else {
			console.error("ERROR: no order id found");
		}
	};

	return (
		<Wrap>
			<div className="billing-form">
				<SubsectionTitle title={t("orderShipping.billingAddress")} />
				<FormContainer>
					<form onSubmit={handleSubmit(handleOnSubmit)} role="form">
						<div className="form-row">
							<div className="input-row">
								<label className="label-sm" htmlFor="first_name">
									{t("form.label.firstName")}
								</label>
								<input
									id="first_name"
									key="first_name"
									className={`size-selector-input`}
									type="text"
									{...register("first_name")}
								/>
								{errors["first_name"] && (
									<small className="error-field">
										{t(`${errors["first_name"]?.message}`)}
									</small>
								)}
							</div>
							<div className="input-row">
								<label className="label-sm" htmlFor="last_name">
									{t("form.label.lastName")}
								</label>
								<input
									id="last_name"
									key="last_name"
									className={`size-selector-input`}
									type="text"
									{...register("last_name")}
								/>
								{errors["last_name"] && (
									<small className="error-field">
										{t(`${errors["last_name"]?.message}`)}
									</small>
								)}
							</div>
						</div>

						<div className="form-row">
							<div className="input-row">
								<label className="label-sm" htmlFor="email">
									{t("form.label.email")}
								</label>
								<input
									id="email"
									key="email"
									className={`size-selector-input`}
									type="text"
									{...register("email")}
								/>
								{errors["email"] && (
									<small className="error-field">
										{t(`${errors["email"]?.message}`)}
									</small>
								)}
							</div>
							<div className="input-row">
								<label className="label-sm" htmlFor="phone">
									{t("form.label.phone")}
								</label>
								<Controller
									control={control}
									name="phone"
									render={({ field: { onChange, value } }) => (
										<PhoneInput
											id="phone"
											className="phone-input"
											value={value}
											onChange={onChange}
											defaultCountry="ES"
											withCountryCallingCode
											placeholder="+34123456789"
										/>
									)}
								/>
								{errors["phone"] && (
									<small className="error-field">
										{t(`${errors["phone"]?.message}`)}
									</small>
								)}
							</div>
						</div>

						<div className="form-row">
							<div className="input-row">
								<label className="label-sm" htmlFor="company_name">
									{t("form.label.company")}
								</label>
								<input
									id="company_name"
									key="company_name"
									className={`size-selector-input`}
									type="text"
									{...register("company_name")}
								/>
								{errors["company_name"] && (
									<small className="error-field">
										{t(`${errors["company_name"]?.message}`)}
									</small>
								)}
							</div>
							<div className="input-row">
								<label className="label-sm" htmlFor="vat_number">
									{t("form.label.vat")}
								</label>
								<input
									id="vat_number"
									key="vat_number"
									className={`size-selector-input`}
									type="text"
									{...register("vat_number")}
								/>
								{errors["vat_number"] && (
									<small className="error-field">
										{t(`${errors["vat_number"]?.message}`)}
									</small>
								)}
							</div>
						</div>

						<div className="form-row">
							<div className="input-row full">
								<label className="label-sm" htmlFor="address_line_1">
									{t("form.label.address1")}
								</label>
								<input
									id="address_line_1"
									key="address_line_1"
									className={`size-selector-input`}
									type="text"
									{...register("address_line_1")}
								/>
								{errors["address_line_1"] && (
									<small className="error-field">
										{t(`${errors["address_line_1"]?.message}`)}
									</small>
								)}
							</div>
						</div>
						<div className="form-row">
							<div className="input-row full">
								<label className="label-sm" htmlFor="address_line_2">
									{t("form.label.address2")}
								</label>
								<input
									id="address_line_2"
									key="address_line_2"
									className={`size-selector-input`}
									type="text"
									{...register("address_line_2")}
								/>
								{errors["address_line_2"] && (
									<small className="error-field">
										{t(`${errors["address_line_2"]?.message}`)}
									</small>
								)}
							</div>
						</div>

						<div className="form-row">
							<div className="input-row">
								<label className="label-sm" htmlFor="postcode">
									{t("form.label.postcode")}
								</label>
								<input
									id="postcode"
									key="postcode"
									className={`size-selector-input`}
									type="text"
									{...register("postcode")}
								/>
								{errors["postcode"] && (
									<small className="error-field">
										{t(`${errors["postcode"]?.message}`)}
									</small>
								)}
							</div>
							<div className="input-row">
								<label className="label-sm" htmlFor="province">
									{t("form.label.province")}
								</label>
								<input
									id="province"
									key="province"
									className={`size-selector-input`}
									type="text"
									{...register("province")}
								/>
								{errors["province"] && (
									<small className="error-field">
										{t(`${errors["province"]?.message}`)}
									</small>
								)}
							</div>
						</div>
						<div className="form-row">
							<div className="input-row">
								<label className="label-sm" htmlFor="city">
									{t("form.label.city")}
								</label>
								<input
									id="city"
									key="city"
									className={`size-selector-input`}
									type="text"
									{...register("city")}
								/>
								{errors["city"] && (
									<small className="error-field">
										{t(`${errors["city"]?.message}`)}
									</small>
								)}
							</div>

							<div className="input-row">
								<label className="label-sm" htmlFor="country">
									{t("form.label.country")}
								</label>
								<select
									id="country"
									key="country"
									className={`size-selector-input`}
									{...register("country")}
								>
									<option key="" value="">
										{t("form.label.selectCountry")}
									</option>
									<option key="es" value="ES">
										{t("vocabulary.spain")}
									</option>
									{countries.map((country: Country) => (
										<option key={country.iso2} value={country.iso2}>
											{country.country}
										</option>
									))}
								</select>

								{errors["country"] && (
									<small className="error-field">
										{t(`${errors["country"]?.message}`)}
									</small>
								)}
							</div>
						</div>
					</form>
					<div className="form-row-button">
						<Button
							style={ButtonEnum.primary}
							onClick={handleSubmit(handleOnSubmit)}
							type={ButtonTypeEnum.button}
						>
							{t("button.saveContinue")}
						</Button>
					</div>
				</FormContainer>
			</div>
		</Wrap>
	);
};

export default BillingForm;
