import styled from "styled-components";
import { rotate } from "../../design-system/animations";
export const Wrap = styled.button`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 19.2px;
	cursor: pointer;
	display: flex;
	min-width: 96px;
	min-height: 40px;
	padding: 10px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	border: 0;

	.loading-spinner {
		display: none;
	}
	&.primary {
		background: var(--purple100);
		color: var(--white);

		&:hover {
			background: var(--purple110);
		}
		&:active {
			background: var(--purple120);
		}
		&:disabled {
			border: 1px solid var(--gray-30);
			background: var(--gray30);
			color: var(--gray90);
			pointer-events: none;
		}
	}

	&.danger {
		background: var(--red100);
		color: var(--white);

		&:hover {
			background: var(--red110);
		}
		&:active {
			background: var(--red120);
		}
		&:disabled {
			border: 1px solid var(--gray-30);
			background: var(--gray30);
			color: var(--gray90);
			pointer-events: none;
		}
	}

	&.secondary {
		background: var(--white);
		border: 1px solid var(--purple100);
		color: var(--purple100);

		&:hover {
			background: var(--purple10);
			color: var(--purple110);
		}
		&:active {
			background: var(--purple10);
			color: var(--purple120);
		}
		&:disabled {
			border: 1px solid var(--gray-30);
			background: var(--gray30);
			color: var(--gray90);
			pointer-events: none;
		}
	}

	&.tertiary {
		background: transparent;
		color: var(--gray130);
		text-decoration-line: underline;
		&:disabled {
			color: var(--gray90);
			background: var(--gray30);
		}
	}
	&.icon,
	&.link,
	&.menu {
		padding: 0;
		background: transparent;
		min-width: auto;
		min-height: auto;
		display: inline-flex;
	}
	&.link {
		min-height: auto;
		line-height: normal;
		min-width: max-content;
	}

	&.menu {
		width: 100%;
		justify-content: flex-start;
		color: var(--gray130);
		height: 40px;
		border-radius: 0;
		padding: 0 8px;

		&:hover {
			background: var(--gray20);
		}
	}

	&.loading {
		pointer-events: none;

		.loading-spinner {
			display: inline-flex;
			svg {
				animation: ${rotate} 2s linear infinite;
				height: 16px;
				width: 16px;
				path {
					fill: white;
				}
			}
		}
	}

	&.small {
		min-height: 20px;
		min-width: auto;
		padding: 4px 8px;
		font-size: 14px;
	}

	&.delete:not([disabled]) {
		color: var(--red100);
		svg {
			path {
				fill: var(--red100);
			}
		}
	}
	&.logo-button {
		svg {
			path {
				fill: var(--gray130);
			}
		}
	}

	&.fullwidth {
		width: 100%;
	}
`;
