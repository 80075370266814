import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getShippingFromOrderThunk } from "../../store/Shipping";
import LoadingOptions from "../LoadingOptions";
import { Wrap } from "./styles";
import { useTranslation } from "react-i18next";
import Alert from "../Alert";
import { AlertEnum } from "../../beans";

type Props = {
	orderId: string;
};
const ShippingDetails = (props: Props) => {
	const { orderId } = props;

	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const shippingState = useSelector((state: RootState) => state.shipping);
	const shipping = shippingState.data?.shipping_address;

	useEffect(() => {
		dispatch(getShippingFromOrderThunk(orderId));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return shipping?.shipping_id ? (
		<Wrap className={!shippingState.data?.valid ? "error" : ""}>
			<div className="list-details">
				<div className="title-sm">{t("shippingDetails.title")}</div>
				<div>
					{shipping?.first_name} {shipping?.last_name}
				</div>
				{shipping?.company_name && <div>{shipping?.company_name}</div>}
				{shipping?.vat_number && <div>{shipping?.vat_number}</div>}
				{shipping?.email && <div>{shipping?.email}</div>}
				{shipping?.phone && <div>{shipping?.phone}</div>}
				{shipping?.address_line_1 && <div>{shipping?.address_line_1}</div>}
				{shipping?.address_line_2 && <div>{shipping?.address_line_2}</div>}
				{shipping?.city && <div>{shipping?.city}</div>}
				{shipping?.postcode && <div>{shipping?.postcode}</div>}
				{shipping?.country && <div>{shipping?.country}</div>}
				{!shippingState.data?.valid && (
					<>
						<Alert type={AlertEnum.error} full noIcon noClose>
							<div
								dangerouslySetInnerHTML={{
									__html: `${t("shippingDetails.dhlError")} <br/> ${shippingState.data?.error?.split(/:(.+)/)[1]}`,
								}}
							/>
						</Alert>
					</>
				)}
			</div>
		</Wrap>
	) : (
		<LoadingOptions />
	);
};

export default ShippingDetails;
