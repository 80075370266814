import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../store";
import { selectRenderItemList } from "../../store/ItemRenderList";
import RenderThumbnail from "../RenderThumbnail";
import ArtworkCage from "../ArtworkCage";

import { Wrap } from "./styles";
import { ArtworkDetail, ItemRender } from "../../beans";
import { getCanvaFromView } from "../../utils/render";
import LoadingCanva from "../LoadingCanva";
import Artwork from "../Artwork";
import ArtworkArea from "../ArtworkArea";
import { selectPrintLocation } from "../../store/PrintLocation";
import { getPrintLoactionByPlacement } from "../../utils/printLocation";
import LoadingIcon from "../LoadingIcon";

type Props = {
	canvas: ItemRender[] | null;
};

const RenderCanva = (props: Props) => {
	const { canvas } = props;
	const dispatch = useDispatch<AppDispatch>();
	const artWorkRef = useRef<HTMLDivElement>(null);

	// selectors
	const selectedPrintLocation = useSelector(
		(state: RootState) => state.printLocations.selected
	);

	const printLocations = useSelector(
		(state: RootState) => state.printLocations.data
	);

	const userDesignState = useSelector((state: RootState) => state.userDesign);

	const selectedItem = useSelector(
		(state: RootState) => state.userDesign?.data?.item_fabric?.item
	);

	const selectedFabricColor = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.col_fabric
	);

	const selectedCanva = useSelector(
		(state: RootState) => state.itemsRenderList.selected
	);

	const artworkDetail = useSelector(
		(state: RootState) => state.userDesign.data?.print_set
	);

	const artworkDetailRequest = useSelector(
		(state: RootState) => state.artworkDetails
	);

	const itemId = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.item?.code
	);

	const itemRenderListState = useSelector(
		(state: RootState) => state.itemsRenderList
	);
	const fabricsState = useSelector((state: RootState) => state.fabrics);
	const tab = useSelector((state: RootState) => state.ui.tab);

	const getCurrentArtwork = () => {
		if (!artworkDetail || !selectedPrintLocation) return;
		const artwork = artworkDetail.find(
			(artwork) =>
				Number(artwork?.location) === Number(selectedPrintLocation?.id)
		);
		setCurrentArtwork(artwork);
	};

	const [currentArtwork, setCurrentArtwork] = useState<
		ArtworkDetail | undefined
	>();

	const [draggable, setDraggable] = useState(false);

	// HOOKS
	useEffect(() => {
		setDraggable(tab.selected === "print" ? true : false);
	}, [tab.selected]);

	useEffect(() => {
		if (selectedItem && selectedFabricColor) getCurrentArtwork();
	}, [selectedItem, selectedFabricColor, artworkDetail, selectedPrintLocation]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedPrintLocation && canvas) {
			const newCanva = getCanvaFromView(
				canvas,
				selectedPrintLocation.placement,
				selectedCanva?.view.includes("avatar")
			);
			if (newCanva) selectCanva(newCanva);
		}
	}, [selectedPrintLocation]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// get first if no canvas is selected
		if (canvas && !selectedCanva) selectCanva(canvas[0]);
		// update selectedCanva
		if (canvas && selectedCanva) {
			const newCanva = canvas.find(
				(canva) => canva.view === selectedCanva.view
			);
			newCanva && selectCanva(newCanva);
		}
	}, [canvas]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideArtworkArea);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideArtworkArea);
	}, []);

	const selectCanva = (canva: ItemRender) => {
		if (canva) {
			dispatch(selectRenderItemList(canva));
			if (printLocations) {
				const printLocation = canva.print_locations[0];

				if (printLocation) {
					dispatch(
						selectPrintLocation(
							getPrintLoactionByPlacement(printLocation, printLocations)
						)
					);
				} else {
					dispatch(selectPrintLocation(null));
				}
			}
		}
	};

	/// TOGGLE CLICK EDITOR AREA
	const handleClickOutsideArtworkArea = (e) => {
		if (!artWorkRef.current?.contains(e.target)) {
			setDraggable(false);
		}
	};
	const handleClickInsideArtworkArea = () => {
		tab.selected === "print" && setDraggable(true);
	};
	/// end toggle click editor area

	return canvas &&
		selectedItem &&
		selectedFabricColor &&
		!itemRenderListState.loading &&
		!fabricsState.loading ? (
		<Wrap>
			<div className="thumbnails">
				{canvas &&
					canvas.map((canva: ItemRender, k: number) => {
						return (
							<RenderThumbnail
								key={`thumb_${k}`}
								selected={canva?.image === selectedCanva?.image}
								onClick={() => selectCanva(canva)}
								canva={canva}
							/>
						);
					})}
			</div>
			<div
				className={
					userDesignState.loading
						? "loading product-preview"
						: "product-preview"
				}
			>
				{userDesignState.loading && <LoadingIcon />}
				{selectedCanva && (
					<img
						className="product-image"
						src={selectedCanva.image}
						alt=""
						draggable="false"
					/>
				)}
				<div ref={artWorkRef} onClick={handleClickInsideArtworkArea}>
					<ArtworkArea
						className={`artwork-area ${selectedPrintLocation?.placement} item_${itemId} ${selectedCanva?.view}`}
					>
						{selectedPrintLocation && (
							<ArtworkCage
								printLocation={selectedPrintLocation}
								show={tab.selected === "print" && draggable}
								htmlId={currentArtwork?.id ?? 0}
								clickable={!(currentArtwork && selectedPrintLocation)}
							/>
						)}
						{currentArtwork && selectedPrintLocation && (
							<Artwork
								itemId={itemId}
								currentArtwork={currentArtwork}
								printLocation={selectedPrintLocation}
								draggable={draggable}
							/>
						)}
					</ArtworkArea>
				</div>
			</div>
			{artworkDetailRequest.loading && <LoadingCanva />}
			{itemRenderListState.loading && <LoadingCanva />}
		</Wrap>
	) : (
		<LoadingCanva />
	);
};

export default RenderCanva;
