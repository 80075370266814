import styled from "styled-components";
import { rotate, loading } from "../../design-system/animations";

export const Wrap = styled.div<{ $text?: boolean }>`
	position: absolute;
	width: 38px;
	height: 38px;
	left: 50%;
	top: 50%;
	padding: 4px;
	transform: translate(-50%, -50%);
	z-index: 99999;
	background: rgba(255, 255, 255, 0.3);
	border-radius: 100%;
	animation: ${loading} 1.8s ease-in-out infinite;

	svg {
		width: 30px;
		height: 30px;
		animation: ${rotate} 2s linear infinite;
		path {
			fill: #7745ff;
		}
		path:nth-child(2) {
			fill: white;
		}
	}
`;
