import { cdn_url } from "../../constants/endpoints";
import { PrintLocation } from "../../beans";
import { checkFileExistence } from "../../utils/check";

const getMaskId = async (itemId: string, printLocation: PrintLocation) => {
	const maskUrl = `${cdn_url}/mask/${itemId}_${printLocation.placement}.svg`;

	// dios perdoname por esto codigo
	// TODO define cages on db
	const maskedItems = [
		"014_front_center",
		"014_full_back",
		"014_hoodie_front",
		"015_full_back",
		"016_front_center",
		"016_full_back",
		"016_hoodie_front",
		"017_full_back",
		"018_front_center",
		"018_full_back",
		"018_hoodie_front",
		"019_full_back",
		"2222_full_center",
		"S2_full_back_ss",
		"S2_hoodie_front_ss",
		"S4_hoodie_front_ss",
		"S4_full_back_ss",
	];

	const hasMask = maskedItems.find(
		(item) => item === `${itemId}_${printLocation.placement}`
	);

	if (hasMask) {
		const response = await checkFileExistence(maskUrl);
		const mask = response ? maskUrl : null;
		return mask;
	} else {
		return null;
	}
};
export default getMaskId;
